// Here you can add other styles
$sc-yellow: #FFD200;
$sc-orange: #F7971E;

.sc-bg-gradient {
  background: $sc-orange;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, $sc-orange, $sc-yellow);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, $sc-orange, $sc-yellow); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.sc-bg-orange {
  background: $sc-orange;
}

.swal-orange {
  background: $sc-orange;
  &:hover {
    background: $sc-yellow !important;
  }
}

.sc-bg-yellow {
  background: $sc-yellow;
}

.sc-bg-orange2 {
  background: #ffc30b;
}